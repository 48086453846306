import React, {useState} from 'react';
import axios from "axios/index";
import validator from 'validator';

const Contactus = () => {
    const [formState, setFormState] = useState({
        name: '', email: '', phone: '', messages: '',
    });

    const [resSuccess, setresSuccess] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [resError, setresError] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [fnameError, setFnameError] = useState('')

    function da(formState) {
        try {
            if (formState.name === "") {
                setFnameError('Name is required');
                setSpinner(true);
                return false;
            } else {
                setFnameError(false);
            }
            if (formState.phone === "") {
                setPhoneError('Phone Number is required');
                setSpinner(true);
                return false;
            } else {
                setPhoneError(false);
            }
            if (!validator.isEmail(formState.email)) {
                setEmailError('Enter valid email address');
                setSpinner(true);
                return false;
            } else {
                setEmailError(false);
            }
            axios.post(`${process.env.API_URL}/contactus`, {
                'name': formState.name, 'email': formState.email, 'phone': formState.phone, 'message': formState.messages,
            }).then((res) => {
                setSpinner(false);
                setresSuccess(true)
                setFormState({
                    name: '', email: '', phone: '', messages: '',
                });
                document.getElementById('textareamessage').value = "";
            }).catch((error) => {
                setresError(true)
            });
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <form className='contactform' onSubmit={(e) => {
                e.preventDefault();
                da(formState);
            }}>
                <div className="row">
                    <div className="col-md-12 mobilenone mb-3">
                        <p className="text-center">
                            If you have any questions, please contact us by filling out the form below and we will get in touch with you shortly.
                            Alternatively, you can get in touch using one of the methods below.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <input placeholder="First Name" className="form-control" value={formState.name} onChange={(e) => setFormState({...formState, name: e.target.value})} type="text"/>
                        <span style={{fontWeight: 'bold', color: 'red',}}>{fnameError}</span>
                    </div>
                    <div className="col-md-12">
                        <input placeholder="Email" className="form-control" value={formState.email} onChange={(e) => setFormState({...formState, email: e.target.value,})} type="email"/>
                        <span style={{fontWeight: 'bold', color: 'red',}}>{emailError}</span>
                    </div>
                    <div className="col-md-12">
                        <input placeholder="Phone Number" className="form-control" value={formState.phone} onChange={(e) => setFormState({...formState, phone: e.target.value,})} type="text"/>
                        <span style={{fontWeight: 'bold', color: 'red',}}>{phoneError}</span>
                    </div>
                    <div className="col-md-12">
                        <textarea className="form-control h-70" placeholder="Message" id='textareamessage'
                                  onChange={(e) => setFormState({...formState, messages: e.target.value,})}>{formState.messages}</textarea>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    <button className="slide_from_left" type="submit"><span className="btnicon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.381" height="20.379" viewBox="0 0 31.381 31.379"><path id="Path_370" data-name="Path 370"
                                                                                                                                 d="M647.7,399.482l-22.405-6-.039-.012c-2.395-.644-3.713.045-4.385.725-.691.68-1.38,2-.737,4.394l.012.041v0l6,22.4a4.58,4.58,0,0,0,2.981,3.454,3.08,3.08,0,0,0,.791.1,3.394,3.394,0,0,0,2.394-1.058l5.23-5.233,6.211.1h.021a1.285,1.285,0,0,0,.9-.372l.005,0c.023-.026.033-.059.055-.085a1.225,1.225,0,0,0,.217-.327,1.249,1.249,0,0,0,.048-.22,1.211,1.211,0,0,0,.056-.255c0-.008,0-.014,0-.02a.159.159,0,0,0,0-.021l-.093-6.211,5.232-5.233a3.253,3.253,0,0,0,.956-3.179,4.581,4.581,0,0,0-3.455-2.985m-25.016-3.475c.2-.2.75-.311,1.663-.11l18.056,15.066.072,4.845-4.844-.074-15.068-18.056c-.2-.913-.09-1.463.121-1.671"
                                                                                                                                 transform="translate(-619.881 -393.21)" fill="#fff"/></svg>
                    </span>
                        <span className="imagectabtnlable">Send Message
                            {spinner && <div className="spinner-border spinner-bordersignup" role="status"><span className="sr-only">Loading...</span></div>}
                        </span>
                    </button>
                </div>
            </form>
            {resError && <div className="alert alert-danger mt-3">Something went wrong. Try Again.</div>}
            {resSuccess && <div className="alert alert-success mt-3">Thanks! we will be respond you soon.</div>}
        </div>
    );
};

export default Contactus;