import React from "react";
import Header from "../partials/header"
import Footer from "../partials/footer"
import {StaticImage} from "gatsby-plugin-image";
import Contactus from "../components/contactus"
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const ContactUSPage = () => {
    //TODO:: Redirect user to myaccount page if they are already logged in
    return (<main>
        <Header/>
        <AruzySeo title={"Contact Us"}/>
        <div className="App topattachbgsection">
            <div className="container changeheading pt-3 pb-5">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="text-center">
                            <HeaderTitle title={'CONTACT US'}/>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mobiledisplay mb-3">
                                <p className="text-center">If you have any questions, please contact us by filling out the form below and we
                                    will get in touch with you shortly. Alternatively, you can get in touch using one of
                                    the methods below.</p>
                            </div>
                            <div className="col-md-4">
                                <StaticImage src='../images/newimage/contactimage.png' alt=''/>
                            </div>
                            <div className='col-md-7'><Contactus/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </main>)
}

export default ContactUSPage